import { SystemInformationsMap } from '@app/models/objects/system-informations-map';

export interface State {
  lastUpdated: SystemInformationsMap;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  lastUpdated: null,
  isLoading: false,
  error: null,
};
