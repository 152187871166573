import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { SystemInformationsResponse } from '@app/models/responses/system-informations.response';

@Injectable({
  providedIn: 'root',
})
export class SystemInformationsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get<IResponseSuccess<SystemInformationsResponse[]>>(
      'general/system/informations',
    );
  }
}
