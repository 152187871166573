import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getLastUpdate = (state: State) => state.lastUpdated;

export const selectState = createFeatureSelector<State>('systemInformations');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectLastUpdate = createSelector(selectState, getLastUpdate);
