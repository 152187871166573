import { createAction, props } from '@ngrx/store';

import { SystemInformationsResponse } from '@app/models/responses/system-informations.response';

export const loadRequest = createAction('[System Informations] Load Request');

export const loadSuccess = createAction(
  '[System Informations] Load Success',
  props<{
    data: SystemInformationsResponse[];
  }>(),
);

export const loadFailure = createAction(
  '[System Informations] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[System Informations] Reset State');
