import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';
import { keyBy, mapValues } from 'lodash';
import { SystemInformationsResponse } from '@app/models/responses/system-informations.response';
import { SystemInformationsMap } from '@app/models/objects/system-informations-map';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { data }) => {
    return {
      ...state,
      lastUpdated: mapValues(
        keyBy(data, ({ key }) => key),
        ({ value }) => value,
      ) as SystemInformationsMap,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function systemInformationsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
